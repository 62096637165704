import React, { useState } from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import { ButtonStyleDark } from "../../Core/Buttons/Styles"
import TextButton from "../../Core/Buttons/TextButton"
import { InputContainerStyle } from "../Styles"
import axios from "axios"

const FormContainer = styled.div`
  ${fonts.garamondRegular};
  max-width: 423px;
  margin: auto;
  width: 100%;
`

const Title = styled.h1`
  ${fonts.garamondRegular};
  color: ${colors.charcoal};
  font-size: 32px;
  margin-top: .67em;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.15;

  @media (min-width: ${breakpoints.md}) {
    font-size: 40px;
    letter-spacing: normal;
  }
`

const InputContainer = styled.div`
  ${InputContainerStyle}
`

const SubmitButton = styled.button`
  ${ButtonStyleDark};
  max-width: unset;
`

const Navigations = styled.div`
  margin-top: 40px;
  display: grid;
  grid-gap: 30px;
  justify-content: center;
  text-align: center;

  a {
    letter-spacing: 1px;
    span {
      padding-bottom: 6px;
    }
  }
`

const SubHeader = styled.div`
  ${fonts.sofiaPro};
  font-size: 16px;
  line-height: 1.33;
  margin: -15px auto 25px;
  text-align: center;
`

const SuccessContainer = styled.div`
  height: 28px;
`;

const SuccessMessage = styled.p`
  padding-top: 12px;
  height: 16px;
`

const RegisterForm = () => {
  const [ email, setEmail ] = useState("")
  const [ firstName, setFirstName ] = useState("")
  const [ lastName, setLastName ] = useState("")
  const [ submitText, setSubmitText ] = useState("Register")
  const [ successMessageDisplay, setSuccessMessageDisplay] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()

    setSubmitText("Processing...")

    const url = '/.netlify/functions/shopifyUpdate'

    const data = {
      "mutationType": "register",
      "data": {
        "firstName": firstName,
        "lastName": lastName,
        "email": email
      }
    }

    try {
      const response = await axios.post(url, data)

      if (response.data.response.customerCreate.customerUserErrors[0].field !== null) {
        setSubmitText("No account found")
      } else {
        setSubmitText("Success")
        setSuccessMessageDisplay(true)
      }
    } catch {
      setSubmitText("Server error")
    }
    
  }

  return (
    <FormContainer>
      <Title>Register</Title>
      <SubHeader>For existing customers</SubHeader>
      <form onSubmit={handleSubmit}>
        <InputContainer>
          <label>Email</label>
          <input
            type="email"
            placeholder="Email"
            name="customer[email]"
            autoCapitalize="off"
            autoComplete="email"
            autoCorrect="off"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <label>First Name</label>
          <input
            type="text"
            placeholder="First Name"
            name="customer[firstName]"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <label>Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            name="customer[lastName]"
            onChange={(e) => setLastName(e.target.value)}
          />
        </InputContainer>
        <SubmitButton type={"submit"}>{submitText}</SubmitButton>
      </form>
      <SuccessContainer>
        {successMessageDisplay ?
          <SuccessMessage>
            Account activation email sent. Please check your email.
          </SuccessMessage>
          :
          <></>
        }
      </SuccessContainer>
      <Navigations>
        <TextButton to="/account/">Already have an account?</TextButton>
      </Navigations>
    </FormContainer>
  )
}

export default RegisterForm
