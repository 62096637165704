import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"

const InputContainerStyle = `
  ${fonts.sofiaPro};
  color: ${colors.charcoal};
  font-size: 12px;
  line-height: 1.15;
  position: relative;
  margin-bottom: 20px;
  transition: all .25s cubic-bezier(.39,.575,.565,1);

  label {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  input, select {
    border: 1px solid ${colors.black};
    width: 100%;
    height: 60px;
    padding: 14px 20px;
    background: transparent;
    margin: 0;
    ::placeholder {
      ${fonts.sofiaPro};
      font-size: 12px;
      color: ${colors.black};
      line-height: normal;
    }
    ${fonts.sofiaPro};
    font-size: 12px;
    color: ${colors.black};
    line-height: normal;
  }

  select {
    appearance: none;
  }

  ${(typeof window === "undefined" || window.localStorage.getItem("ili") !== "true") ?
    '':'input:focus {background-color:' + colors.purple + '; }'
  }
`

const CheckboxContainerStyle = `
  ${fonts.sofiaPro};
  color: ${colors.charcoal};
  font-size: 12px;
  line-height: 1.15;
  position: relative;
  margin-top: -10px;
  margin-bottom: 30px;

  label {
    position: absolute;
    color: ${colors.greyExtraLight}
  }

  input {
    appearance: none;
    margin: 0 10px 0 0;
    cursor: pointer;
    padding: 0;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    width: 100%;
    position: relative;
    border-radius: 50%;
    border-color: ${colors.greyExtraLight};
    transition: all .2s cubic-bezier(.445,.05,.55,.95);
    background-color: transparent;
    border: 1px solid ${colors.greyExtraLight};
    outline: none;
  }

  input:checked {
    background-color: ${colors.purple};
    background-clip: content-box;
    padding: 3px;
  }

  input[type=checkbox]:checked + label {
    ${fonts.sofiaProBold};
    color: ${colors.greyDark};
    font-size: 14px;
  }
`

export { InputContainerStyle, CheckboxContainerStyle }
